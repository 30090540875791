import * as React from "react"
import "wipe.css"

import { Typography } from "../UI/Typography"
import { ThemeProvider, createGlobalStyle } from "../styled"
import theme from "./theme"
import { AppProvider as ModalProvider } from "../Modal/Provider"

const GlobalStyle = createGlobalStyle`

  #gatsby-focus-wrapper{
    overflow-x: hidden;
  }

  body {
    background: ${({ theme: { colors } }) => colors.grayscale.light};

    .headroom.headroom--scrolled header > div {
      padding-top: 1rem;
      padding-bottom: 1rem;
      transition: ${({ theme }) => theme.transition.ease(200)}

      ${({ theme: { media } }) => media.above("large")}{
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }

    .headroom header > div {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      transition: ${({ theme }) => theme.transition.ease(200)}

      ${({ theme: { media } }) => media.above("large")}{
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }

    .headroom {
      z-index: 10!important;
      width: 100vw;

      svg{
        display: none;
      }
    }
  }
`

const Provider: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <main>
          <GlobalStyle />
          <Typography />
          {children}
        </main>
      </ModalProvider>
    </ThemeProvider>
  )
}

export default Provider
