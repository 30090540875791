import * as React from "react"

import { globalWindow } from "../../utils/constants"
import Modal from "./Modal"

export const Context = React.createContext({
  setIsSuccessful: (arg: boolean) => {
    return
  },
  setModalOpen: (arg: boolean) => {
    return
  },
})

export const AppProvider: React.FC = ({ children }) => {
  const [isSuccessful, setIsSuccessful] = React.useState(false)
  const [modalOpen, setModalOpen] = React.useState(false)

  React.useEffect(() => {
    if (globalWindow) {
      const triggerSubscription =
        (global as any).window.location.search.replace("?", "") ===
        "triggerSubscription"
      const triggerSubscriptionSuccess =
        (global as any).window.location.search.replace("?", "") ===
        "triggerSubscriptionSuccess"

      if (triggerSubscription) {
        setModalOpen(true)
      } else if (triggerSubscriptionSuccess) {
        // Send render to end of queue
        setTimeout(() => {
          setModalOpen(true)
          setIsSuccessful(true)
        }, 1)
      }
    }
  }, [])

  React.useEffect(() => {
    if (modalOpen === false) {
      setIsSuccessful(false)
    }
  }, [modalOpen])

  return (
    <Context.Provider
      value={{
        setIsSuccessful,
        setModalOpen,
      }}
    >
      {children}

      <Modal
        isSuccessful={isSuccessful}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        setIsSuccessful={setIsSuccessful}
      />
    </Context.Provider>
  )
}
