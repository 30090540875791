import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"

import styled, { createGlobalStyle, css } from "../styled"
import Form from "../../components/Form/form"
import { Container } from "../UI/Grid/Grid"
import getColumnSize from "../../utils/getColumnSize"
import { Title, Text, CounterTitle } from "../UI/Typography"
import { Button } from "../UI"
import LinkWrap from "../UI/Link"
import formatStrongString from "../../utils/formatStrongString"

const ModalWrapper = styled.div<{ modalOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.grayscale.modalBackground};
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 20;
  display: ${({ modalOpen }) => (modalOpen ? `flex` : `none`)};
`

const ModalBuffer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

const ModalInner = styled.div`
  position: relative;
  width: ${getColumnSize(12)};
  background-color: ${({ theme }) => theme.colors.grayscale.light};
  border-radius: 8px;
  padding: 80px ${getColumnSize(1)} 0;
  text-align: center;

  ${({ theme }) => theme.media.above("large")} {
    width: ${getColumnSize(6)};
  }
`

const Label = styled(CounterTitle)`
  position: relative;
  margin: 0 auto 12px;
  padding: 5px 4px 4px;
  background-color: ${({ theme }) => theme.colors.grayscale.counterBackground};
  max-width: fit-content;
  width: 100%;
  border-radius: 4px;
`

const TitleContent = styled(Title)`
  margin-bottom: 16px;
`

const TextContent = styled(Text)`
  margin-bottom: 32px;
`

const UpperTextContent = styled(Text)`
  margin-bottom: 0px;

  ${({ theme }) => theme.media.above("large")} {
    margin-bottom: 10px;
  }
`

const ImageWrapper = styled.div`
  max-width: 321px;
  margin: 23px auto 0;
`

const Close = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  height: 14px;
  width: 14px;
  cursor: pointer;

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.grayscale.dark};
  }
  &:after {
    transform: rotate(45deg);
  }
  &:before {
    transform: rotate(-45deg);
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  flex-direction: column;

  ${({ theme }) => theme.media.above("medium")} {
    flex-direction: row;
  }
  ${({ theme }) => theme.media.above("large")} {
    margin-bottom: 36px;
  }
`

const ButtonModal = styled(Button)`
  margin: 0 10px 16px;

  ${({ theme }) => theme.media.above("medium")} {
    margin: 0 10px;
  }

  ${({ ghost }) =>
    ghost &&
    css`
      color: ${({ theme: { colors } }) => colors.grayscale.dark};
      border-color: ${({ theme: { colors } }) => colors.grayscale.mediumLight};

      &:hover {
        border-color: ${({ theme: { colors } }) => colors.grayscale.dark};
        background-color: transparent;
        color: ${({ theme: { colors } }) => colors.grayscale.dark};
      }
    `}
`

const LowerWrapper = styled.div`
  padding: 0px 20px 20px 20px;
  background-color: transparent;
  margin-bottom: 32px;

  ${({ theme }) => theme.media.above("large")} {
    padding: 20px 20px 20px 20px;
    background-color: ${({ theme }) => theme.colors.grayscale.modalSocial};
    margin-bottom: 40px;
  }
`

const SocialWrapper = styled.div`
  position: relative;
`

const SocialBtn = styled(LinkWrap)`
  position: relative;
  width: 24px;
  margin: 0 6px;
`

const ModalContainer = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TopDetail = styled.div`
  position: absolute;
  top: 0;
  left: 36px;
  width: 100%;
  max-width: 142px;
  display: none;

  ${({ theme }) => theme.media.above("large")} {
    display: block;
  }
`

const BottomLeftDetail = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 159px;
  display: none;

  ${({ theme }) => theme.media.above("large")} {
    display: block;
  }
`

const BottomRightDetail = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 134px;
  display: none;

  ${({ theme }) => theme.media.above("large")} {
    display: block;
  }
`

const Modal: React.FC<{
  modalOpen: boolean
  isSuccessful: boolean
  setModalOpen: (arg: boolean) => void
  setIsSuccessful: (arg: boolean) => void
}> = ({ isSuccessful, modalOpen, setModalOpen, setIsSuccessful }) => {
  const data: ModalProps = useStaticQuery(query)
  const content = data.contentYaml.homepage.modal
  const successfulContent = data.contentYaml.homepage.form.success
  const buttonProps: ButtonType = [{ ghost: false }, { ghost: true }]

  if (isSuccessful) {
    return (
      <>
        <ModalWrapper modalOpen={modalOpen}>
          <ModalBuffer onClick={() => setModalOpen(!modalOpen)} />
          <ModalContainer>
            <ModalInner>
              <Close onClick={() => setModalOpen(!modalOpen)} />
              <TitleContent>{successfulContent.title}</TitleContent>
              <UpperTextContent>
                {successfulContent.text
                  .split("\n")
                  .filter(Boolean)
                  .map((item, index) => {
                    return [
                      item,
                      <React.Fragment key={index}>
                        <br />
                        <br />
                      </React.Fragment>,
                    ]
                  })}
              </UpperTextContent>
              <ButtonWrapper>
                {successfulContent.buttons.map((button, index) => {
                  return (
                    <ButtonModal
                      key={button.button_text}
                      to={button.button_link}
                      ghost={buttonProps[index].ghost}
                    >
                      {button.button_text}
                    </ButtonModal>
                  )
                })}
              </ButtonWrapper>
              <LowerWrapper>
                <TextContent>
                  {formatStrongString(successfulContent.social.text)}
                </TextContent>
                <SocialWrapper>
                  {successfulContent.social.social_btn.map(social => {
                    return (
                      <SocialBtn key={social.alt} to={social.link}>
                        <Img
                          alt={social.alt}
                          fluid={social.icon.childImageSharp.fluid}
                        />
                      </SocialBtn>
                    )
                  })}
                </SocialWrapper>
                <TopDetail>
                  <Img fluid={content.detail1.childImageSharp.fluid} />
                </TopDetail>
                <BottomLeftDetail>
                  <Img fluid={content.detail2.childImageSharp.fluid} />
                </BottomLeftDetail>
                <BottomRightDetail>
                  <Img fluid={content.detail3.childImageSharp.fluid} />
                </BottomRightDetail>
              </LowerWrapper>
            </ModalInner>
          </ModalContainer>
        </ModalWrapper>
      </>
    )
  } else {
    return (
      <>
        <ModalWrapper modalOpen={modalOpen}>
          <ModalContainer>
            <ModalBuffer onClick={() => setModalOpen(!modalOpen)} />
            <ModalInner>
              <Close onClick={() => setModalOpen(!modalOpen)} />
              <Label>{content.label}</Label>
              <TitleContent>{content.title}</TitleContent>
              <TextContent>{content.subtitle}</TextContent>
              <Form onSuccess={() => setIsSuccessful(true)} />
              <ImageWrapper>
                <Img fluid={content.image.childImageSharp.fluid} />
              </ImageWrapper>
            </ModalInner>
          </ModalContainer>
        </ModalWrapper>
      </>
    )
  }
}

type ButtonType = Array<{
  [key: string]: boolean
}>

interface ModalProps {
  contentYaml: {
    homepage: {
      modal: {
        image: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
        label: string
        subtitle: string
        title: string
        detail1: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
        detail2: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
        detail3: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
      }
      form: {
        success: {
          text: string
          title: string
          buttons: Array<{
            button_link: string
            button_text: string
          }>
          social: {
            text: string
            social_btn: Array<{
              icon: {
                childImageSharp: {
                  fluid: FluidObject
                }
              }
              link: string
              alt: string
            }>
          }
        }
      }
    }
  }
}

export const query = graphql`
  query Modal {
    contentYaml(homepage: { button: { ne: null } }) {
      homepage {
        modal {
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          label
          subtitle
          title
          detail1 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          detail2 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          detail3 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        form {
          success {
            text
            title
            buttons {
              button_link
              button_text
            }
            social {
              text
              social_btn {
                icon {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
                link
                alt
              }
            }
          }
        }
      }
    }
  }
`

export default Modal
