import * as React from "react"
import styled, { css } from "../styled"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img, { FluidObject } from "gatsby-image"

import getColumnSize from "../../utils/getColumnSize"
import { Container } from "../UI/Grid/Grid"
import LinkWrap from "../UI/Link"
import LeaveArrow from "../../../content/images/leave-icon-black.png"
import { Text } from "../UI/Typography"
import { Logo } from "../UI/Logo"

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: space-between;

  ${({ theme }) => theme.media.above("large")} {
    padding: 24.5px 0;
    flex-direction: row;
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.grayscale.inputBorder};

    &:last-child {
      border-bottom: none;
    }
  }
`

const LogoCol = styled.div`
  flex: 1 1 ${getColumnSize(3)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayscale.paleWhite};

  ${({ theme }) => theme.media.above("large")} {
    border-bottom: 0;
  }
`

const LogoColWrapper = styled(Link)`
  margin-bottom: 16px;
  height: 25px;
  width: 100%;

  ${({ theme }) => theme.media.above("large")} {
    margin-bottom: 0;
  }
`

const NavCol = styled.div`
  flex: 1 1 ${getColumnSize(6)};
`

const NavColWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 auto;
  flex-direction: column;

  ${({ theme }) => theme.media.above("large")} {
    max-width: fit-content;
    flex-direction: row;
  }

  &.alignLeft {
    margin: 0;
  }
`

const NavLink = styled(LinkWrap)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grayscale.dark};
  transition: ${({ theme }) => theme.transition.ease(200)};
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayscale.paleWhite};
  display: inline-flex;

  &:after {
    display: ${props => (props.goesOut ? "block" : "none")};
    content: "";
    position: relative;
    background-image: url(${LeaveArrow});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 4px;
    width: 8px;
    transition: ${({ theme: { transition } }) => transition.ease(200)};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.grayscale.mediumLight};

    &:after {
      transform: translate(2px, -2px);
      opacity: 0.5;
    }
  }

  ${({ theme }) => theme.media.above("large")} {
    padding: 0;
    border-bottom: 0;
    margin-right: 24px;

    &:last-child {
      margin-right: 24px;
    }
  }
`

const SocialLink = styled.a`
  max-width: 24px;
  width: 100%;
  margin: 0 6px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

const CopyrightLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.grayscale.mediumLight};
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 22px;
  align-self: flex-end;
  padding: 16px 0;
  transition: ${({ theme }) => theme.transition.ease(200)};
  width: 100%;
  flex: 2;

  ${({ theme }) => theme.media.above("large")} {
    padding: 0;
    border-bottom: 0;
    text-align: right;
  }
`

export const socialColStyles = css`
  flex: 1 1 ${getColumnSize(12)};
  display: flex;
  justify-content: start;
  padding-top: 16px;

  ${({ theme }) => theme.media.above("large")} {
    flex: 1 1 ${getColumnSize(3)};
    justify-content: flex-end;
    padding-top: 0;
  }
`

const SocialColMobile = styled.div`
  ${socialColStyles};
  margin-top: 62px;
  margin-bottom: 19px;
  ${({ theme }) => theme.media.above("large")} {
    display: none;
  }
`

const SocialColDesktop = styled.div`
  ${socialColStyles};
  display: none;
  ${({ theme }) => theme.media.above("large")} {
    display: flex;
  }
`

const Footer = () => {
  const data: FooterType = useStaticQuery(query)
  const content = data.contentYaml.footer
  return (
    <Container>
      <FooterWrapper>
        <LogoCol>
          <LogoColWrapper to="/">
            <Logo fontSize="16px">{content.logo}</Logo>
          </LogoColWrapper>
        </LogoCol>
        <NavCol>
          <NavColWrapper>
            {content.footer_nav.map(({ link_text, link_url, goes_out }) => {
              return (
                <NavLink key={link_text} to={link_url} goesOut={goes_out}>
                  {link_text}
                </NavLink>
              )
            })}
          </NavColWrapper>
        </NavCol>
        <SocialColDesktop>
          {content.social.map(social => {
            return (
              <SocialLink
                href={social.src}
                target="_blank"
                rel="noopener norefer"
                key={social.alt}
              >
                <Img
                  alt={social.alt}
                  fluid={social.icon.childImageSharp.fluid}
                />
              </SocialLink>
            )
          })}
        </SocialColDesktop>
      </FooterWrapper>

      <FooterWrapper>
        <NavCol>
          <NavColWrapper className="alignLeft">
            {content.footer_secondary_nav.map(
              ({ link_text, link_url, goes_out }) => {
                return (
                  <NavLink key={link_text} to={link_url} goesOut={goes_out}>
                    {link_text}
                  </NavLink>
                )
              }
            )}
          </NavColWrapper>
        </NavCol>
        <CopyrightLabel aria-disabled>{content.copyright}</CopyrightLabel>
        <SocialColMobile>
          {content.social.map(social => {
            return (
              <SocialLink
                href={social.src}
                target="_blank"
                rel="noopener norefer"
                key={social.alt}
              >
                <Img
                  alt={social.alt}
                  fluid={social.icon.childImageSharp.fluid}
                />
              </SocialLink>
            )
          })}
        </SocialColMobile>
      </FooterWrapper>
    </Container>
  )
}

interface FooterType {
  contentYaml: {
    footer: {
      footer_nav: [
        {
          goes_out: boolean
          link_text: string
          link_url: string
        }
      ]
      footer_secondary_nav: [
        {
          goes_out: boolean
          link_text: string
          link_url: string
        }
      ]
      social: Array<{
        label: string
        src: string
        icon: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
        alt: string
      }>
      logo: string
      copyright: string
    }
  }
}

export const query = graphql`
  query Footer {
    contentYaml(homepage: { button: { ne: null } }) {
      footer {
        footer_nav {
          goes_out
          link_text
          link_url
        }
        footer_secondary_nav {
          goes_out
          link_text
          link_url
        }
        social {
          label
          src
          icon {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          alt
        }
        logo
        copyright
      }
    }
  }
`

export default Footer
