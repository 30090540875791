import * as React from "react"

export default (msg?: string, email?: string): React.ReactNode => {
  if (msg === "Member Exists") {
    return <p>{email} is already subscribed.</p>
  }

  if (msg && /has too many recent signup/.test(msg)) {
    return <p>Recipient {email} has too many recent signup requests</p>
  }

  return <p>There is something wrong, try again.</p>
}
