import styled, { createGlobalStyle, css } from "../../styled"
import { IThemeInterface } from "../../Provider/types"

const fontFace = require("./fonts/index.css")

export interface IProp {
  color?: string
  textTransform?:
    | "none"
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "initial"
    | "inherit"
}

export interface IAplyColor {
  theme: IThemeInterface
  color?: string
}

function applyColor(props: IAplyColor): string {
  const { colors } = props.theme

  const map: any = {
    black: colors.grayscale.black,
    blue: colors.blue.default,
    blueDark: colors.blue.dark,
    default: colors.grayscale.black,
    grey: colors.grayscale.default,
    greyDark: colors.grayscale.dark,
    greyLight: colors.grayscale.light,
    mediumLight: colors.grayscale.mediumLight,
    greyMedium: colors.grayscale.medium,
    orange: colors.orange.default,
    red: colors.red.default,
    white: colors.grayscale.white,
  }

  if (props.color !== undefined) {
    return map[props.color]
  }

  return map.default
}

// **
// Base
// **
export const Typography = createGlobalStyle`
  ${fontFace}
  
  body, input, button, select {
    font-family: 'AvertaStd', "Arial", "Helvetica", sans-serif;
  }
`

export const base = css`
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  color: ${applyColor};
`

export const baseTitles = css`
  ${base};
  font-weight: 700;
`

// **
// Main Title
// **
export const mainTitleStyle = css`
  ${baseTitles};
  line-height: 2.5rem;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.5rem;
  font-family: "AvertaStd-Black", "Arial", "Helvetica", sans-serif;

  ${({ theme: { media } }) => css`
    ${media.above("medium")} {
      font-size: 4.5rem;
      line-height: 5rem;
    }
  `}
`

export const MainTitle = styled.h1`
  ${mainTitleStyle};
`

// **
// Title
// **
export const titleStyle = css`
  ${baseTitles};
  font-size: 2rem;
  line-height: 36px;
  letter-spacing: -0.6px;
  font-weight: 500;

  ${({ theme }) => theme.media.above("large")} {
    line-height: 50px;
  }
`

const spanHighlight = css`
  span {
    position: relative;
    .highlight {
      &:after {
        position: absolute;
        top: 3.5px;
        left: -2px;
        right: -2px;
        bottom: 3px;
        background-color: ${({theme}) => theme.colors.blue.default};
        content: '';
        z-index: -1;
        opacity: 0.4;

      }
    }
  }
`

export const Title = styled.h1`
  ${base};
  ${titleStyle};
  font-size: 2rem;
  font-weight: 500;
  ${({ theme }) => theme.media.above("large")} {
    font-size: 3rem;
  }
  z-index: 1;
  ${spanHighlight};
`

// **
// SubTitle
// **
export const subTitleStyle = css`
  ${baseTitles};
  font-size: 0.625rem;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
`

export const SubTitle = styled.h2`
  ${subTitleStyle};
`

// **
// Perks
// **

export const perksStyle = css`
  ${baseTitles};
  line-height: 22px;
  letter-spacing: -0.2px;
`

export const PerksTitle = styled.h3`
  ${perksStyle};
  font-size: 1rem;
`

export const PerksText = styled.p`
  ${perksStyle};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
`

// **
// Caption
// **
export const captionStyle = css`
  ${base};
  font-weight: 500;
  font-size: 1.35rem;
  line-height: 1.35;
`

export const Caption = styled.h4`
  ${captionStyle};
`

// **
// Hero Counters
// **
export const CounterTitleStyle = css`
  ${base};
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 0.875em;
  text-transform: uppercase;
`

export const CounterTitle = styled.p`
  ${CounterTitleStyle};
`
export const CounterNumberStyle = css`
  ${base};
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.125rem;
  margin-bottom: 0.125rem;

  ${({ theme }) => theme.media.above("large")} {
    font-size: 3rem;
    line-height: 3.125rem;
    margin-bottom: 0;
  }
`

export const CounterNumber = styled.p`
  ${CounterNumberStyle};
`

// **
// Testimonials
// **

export const testimonialStyle = css`
  ${base};
  font-weight: 500;
  font-size: 1.35rem;
  line-height: 18px;
`

export const TestimonialName = styled.p`
  ${testimonialStyle};
  font-size: 0.875rem;
`

export const TestimonialJob = styled.p`
  ${testimonialStyle};
  font-size: 0.875rem;
  font-weight: 400;
`

export const TestimonialText = styled.p`
  ${testimonialStyle};
  font-size: 0.875rem;
  font-weight: 400;
`

// **
// Description
// **
export const descriptionStyle = css`
  ${base};
  font-size: 1rem;
  line-height: 1.35;
  border-color: ${applyColor};

  ${({ theme: { media } }) => css`
    ${media.above("medium")} {
      font-size: 1.25rem;
    }
  `}
`

export const Description = styled.p`
  ${descriptionStyle};
`

// **
// BlogText
// **
export const blogTextStyle = css`
  ${base};
  font-size: 1.125rem;
  line-height: 1.5;
`

export const BlogText = styled.p`
  ${blogTextStyle};
`

// **
// TextLink
// **
export const textLinkStyle = css`
  ${base};
  font-size: 1.125rem;
  line-height: 1.3;
  font-weight: 500;
`

export const TextLink = styled.p`
  ${textLinkStyle};
`

// **
// Text
// **
export const textStyle = css`
  ${base};
  font-size: 1rem;
  line-height: 22px;
  letter-spacing: -0.2px;
`

export const Text = styled.p`
  ${textStyle};
`

// **
// TextBold
// **
export const textStyleBold = css`
  ${base};
  font-weight: 700;
  font-size: 1rem;
  line-height: 22px;
  letter-spacing: -0.2px;
`

export const TextBold = styled.p`
  ${textStyleBold};
`

// **
// FootNote
// **
export const footNoteStyle = css`
  ${base};
  font-size: 0.65rem;
  line-height: 1.5;
  text-transform: uppercase;
`

export const FootNote = styled.p`
  ${footNoteStyle};
`

// **
// Tag
// **
export const tagStyle = css`
  ${base};
  background: rgba(0, 123, 255, 0.1);
  border-radius: 2px;
  color: ${({ theme: { colors } }) => colors.blue.default};
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1;
  margin-bottom: 1rem;
  padding: 0.5em 0.5em 0.3em;
  text-transform: uppercase;
`

export const Tag = styled.p`
  ${tagStyle};
`
