export type Breakpoints =
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge"

export type BreakpointsObj = {
  [key in Breakpoints]: { width: string; gutter: number }
}

const columns = 12

const breakpoints: BreakpointsObj = {
  xsmall: { width: "0rem", gutter: 5 },
  small: { width: "36rem", gutter: 5 },
  medium: { width: "48rem", gutter: 32 },
  large: { width: "62rem", gutter: 16 },
  xlarge: { width: "80rem", gutter: 16 },
  xxlarge: { width: "120rem", gutter: 16 },
}

export default {
  colors: {
    blue: {
      dark: "#000C20",
      default: "#464DFF",
      hover: "#3F45E6",
      highlight: "#D6D6FB",
      light: "#EBF5FF",
      middle: "#464DFF",
    },
    grayscale: {
      black: "#000",
      dark: "#0A0A16",
      modalBackground: "#000000c7",
      default: "#8A94A6",
      medium: "#53627C",
      mediumLight: "#949EB3",
      inputBorder: "#EDF1F5",
      light: "#FAFAFA",
      lightMedium: "#DEE1E8",
      paleWhite: "#949eb31a",
      counterBackground: "#0a0a160d",
      white: "#ffffff",
      shadowCard: "#53627c0f",
      shadowHeader: "#949eb333",
      activeForm: "#DEE2E6",
      modalSocial: "#F7F8F9",
    },
    orange: {
      default: "#F7A736",
    },
    red: {
      default: "#EE3465",
    },
  },
  shadow: {
    default: "0 2px 12px 0 rgba(0, 0, 0, 0.03)",
    secondary: "0 3px 10px 0 rgba(0, 0, 0, 0.2)",
  },
  transition: {
    cubic: (ms = 300) => `cubic-bezier(0, 1.2, 1, 1) ${ms}ms`,
    ease: (ms = 200) => `${ms}ms ease-in-out`,
  },
  media: {
    above: (customSize: Breakpoints) => {
      if (breakpoints[customSize]) {
        return `@media screen and (min-width: ${breakpoints[customSize].width})`
      }

      return `@media screen and (min-width: ${customSize})`
    },

    // other medias
    hover: `@media (hover: hover)`,
  },
  grid: { columns, breakpoints },
}
