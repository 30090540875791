import React from "react"
import styled, { CSSProperties } from "styled-components"
import { Text } from "../Typography"
import icon from "../../../images/eth-icon.png"

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 8.5rem;
  max-width: fit-content;
  background-size: 100%;
  color: transparent;
  user-select: none;
  background: url(${icon}) left no-repeat;
  background-size: auto 100%;
  padding-left: 30px;
  height: 100%;
  min-height: fit-content;
  align-items: center;
`

const Title = styled(Text)<FontSizeProp>`
  font-size: ${({ fontSize }) => fontSize || "20px"};
`

type FontSizeProp = {
  fontSize?: CSSProperties["fontSize"]
}

export const Logo: React.FC<FontSizeProp> = ({ fontSize }) => {
  return (
    <Wrapper>
      <Title fontSize={fontSize}>Ethereum World</Title>
    </Wrapper>
  )
}
