import React from "react"

const formatStrongString = (data: string): React.ReactNode => {
  const split = data.split(/(\*\*.+\*\*)/gm)
  const createElement = split.reduce((acc: Array<any>, curr) => {
    if (/(\*\*.+\*\*)/gm.test(curr)) {
      acc.push(<strong>{curr.replace(/\*/gm, "")}</strong>)
    } else {
      acc.push(curr)
    }

    return acc
  }, [])

  return createElement
}

export default formatStrongString
