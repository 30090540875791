import styled from "../styled"
import { Button } from "../UI"

export const FormBtn = styled(Button)`
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
  transform: translateX(-1px);
`

export const Form = styled.form`
  max-width: 100%;
  display: flex;
  background: ${({ theme: { colors } }) => colors.grayscale.white};
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.03);

  @media (max-width: 600px) {
    max-width: 100%;
  }

  input {
    flex: 1;
    position: relative;
    top: 0.1em;
    padding: 0.75rem 1rem;
    transform: translateY(-1px);
    color: ${({ theme: { colors } }) => `${colors.grayscale.dark}`};
    border: 1px solid ${({ theme: { colors } }) => colors.grayscale.inputBorder};
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 4px;
    transition: ${({ theme }) => theme.transition.ease(200)};
    width: 50%;

    &::placeholder {
      color: ${({ theme: { colors } }) => `${colors.grayscale.mediumLight}`};
    }

    &:focus {
      border: 1px solid
        ${({ theme: { colors } }) => colors.grayscale.activeForm};
    }

    ${({ theme }) => theme.media.above("medium")} {
      width: unset;
    }
  }

  .normal-view {
    display: none;

    @media (min-width: 400px) {
      display: block;
    }
  }

  .small-view {
    display: block;

    @media (min-width: 400px) {
      display: none;
    }
  }
`
export const SocialLink = styled.a`
  color: ${({ theme: { colors } }) => colors.grayscale.default};
  margin: 1em 0.3em;
  transition: opacity ${({ theme: { transition } }) => transition.ease()};

  &:hover {
    opacity: 0.8;
  }
`

export const MessageError = styled.p`
  color: ${({ theme }) => theme.colors.red.default};
  margin-top: 0.4em;
`
