import { Link } from "gatsby"
import * as React from "react"

import styled, { css } from "../styled"
import { IThemeInterface } from "../Provider/types"
import LeaveArrow from "../../../content/images/leave-icon.png"

export interface IProp {
  ghost?: boolean
  disabled?: boolean
  goesOut?: boolean
  to?: string
  href?: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  children: React.ReactNode
  type?: "button" | "reset" | "submit" | undefined
}

export interface IAplyProps extends IProp {
  theme: IThemeInterface
}

const themeStyle = (props: IAplyProps) => {
  const {
    ghost,
    theme: { colors },
  } = props

  if (ghost) {
    return css`
      color: ${colors.blue.default};
      border: 1px solid ${colors.blue.default};
      outline: none;

      &:hover {
        border: 1px solid ${colors.blue.default};
        background: ${colors.blue.default};
        color: ${colors.grayscale.white};
        cursor: pointer;
      }
    `
  }

  return css`
    color: ${colors.grayscale.white};
    border: 1px solid ${colors.blue.default};
    background: ${colors.blue.default};
    outline: none;

    &:hover {
      border: 1px solid ${colors.blue.middle};
      background: ${colors.blue.hover};
      cursor: pointer;
    }

    &:active {
      color: ${colors.grayscale.white};
      border: 1px solid ${colors.blue.middle};
      background: ${colors.blue.middle};
    }
  `
}

export const buttonStyle = css`
  border-radius: 4px;
  padding: 0.75rem 1.25rem;
  line-height: 1.375rem;
  font-weight: 500;
  transition: all ${({ theme: { transition } }) => transition.ease()};
  display: ${props => (props.goesOut ? "inline-flex" : "inline-block")};

  ${themeStyle};

  &:after {
    display: ${props => (props.goesOut ? "block" : "none")};
    content: "";
    background-image: url(${LeaveArrow});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 9px;
    height: 22px;
    position: relative;
    margin-left: 6px;
    transition: ${({ theme: { transition } }) => transition.ease(200)};
  }

  &:hover {
    &:after {
      transform: translate(2px, -2px);
    }
  }
`

const Button = styled.button<IProp>`
  ${buttonStyle};
`

const CustomLink = styled(Link)<IProp>`
  ${buttonStyle};
`

const ButtonWrap: React.FC<IProp> = ({
  to,
  children,
  onClick,
  href,
  ghost,
  goesOut,
  ...props
}) => {
  if (to) {
    return (
      <CustomLink ghost={ghost} to={to} {...props}>
        {children}
      </CustomLink>
    )
  }

  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener norefer">
        <Button
          ghost={ghost}
          goesOut={goesOut}
          onClick={onClick}
          aria-label="button"
          role="presentation"
          {...props}
        >
          {children}
        </Button>
      </a>
    )
  }

  return (
    <Button
      ghost={ghost}
      onClick={onClick}
      aria-label="button"
      role="presentation"
      {...props}
    >
      {children}
    </Button>
  )
}

export default ButtonWrap
