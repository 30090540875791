import styled, { css } from "../../styled"

const horizontalGutter = (gutter: number) => css`
  margin: 0 -${gutter / 2}px;
  max-width: calc(100% + ${gutter}px);

  & > * {
    padding: 0 ${gutter / 2}px;
  }
`

export const Grid = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  ${({ theme: { media, grid } }) => css`
    ${media.above("small")} {
      ${horizontalGutter(grid.breakpoints.small.gutter)}
    }

    ${media.above("medium")} {
      ${horizontalGutter(grid.breakpoints.medium.gutter)}
    }

    ${media.above("large")} {
      ${horizontalGutter(grid.breakpoints.large.gutter)}
    }
  `}
`

export const Container = styled.div`
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  max-width: 100%;
  margin: 0 auto;

  ${({ theme: { media } }) => css`
    ${media.above("medium")} {
      max-width: 85%;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    ${media.above("large")} {
      max-width: 85rem;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  `}
`
