import * as React from "react"
import styled, { createGlobalStyle, css } from "../styled"
import { Link, graphql, useStaticQuery } from "gatsby"
import Headroom from "react-headroom"
import Img, { FluidObject } from "gatsby-image"

import { Container } from "../UI/Grid/Grid"
import Button from "../UI/Button"
import LinkWrap from "../UI/Link"
import LeaveArrow from "../../../content/images/leave-icon-black.png"
import { Logo } from "../UI/Logo"
import { motion } from "framer-motion"

export const DisableScroll = createGlobalStyle<{ active: boolean }>`
    body, html {
      overflow: hidden;
      position: fixed;
      top: 0;
      width: ${({ active }) => (active ? "100%" : "unset")};
    }
`

const HeaderWrapper = styled.header<{ bgColor: any }>`
  background-color: ${({ bgColor }) => (bgColor ? bgColor : `#fafafa`)};
`

const HeaderContainer = styled(Container)<{ bgColor: any }>`
  position: relative;
  z-index: 10;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : `#fafafa`)};
  ${({ theme }) => theme.media.above("large")} {
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LogoWrapper = styled(Link)`
  flex: 1 1 8.5rem;
`

const CustomLink = css`
  margin-left: 1.6em;
  transition: ${({ theme }) => theme.transition.ease()};

  &:hover {
    color: ${({ theme }) => theme.colors.grayscale.mediumLight};
    &:after {
      opacity: 0.4;
    }
  }
`

const DesktopLink = styled.a`
  ${CustomLink};
  position: relative;
  padding-right: 12px;
  &:after {
    content: "";
    position: absolute;
    display: block;
    background-image: url(${LeaveArrow});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 6px;
    width: 8px;
    transition: ${({ theme: { transition } }) => transition.ease(200)};
    top: 0;
    right: 0;
    bottom: 0;
  }
`

const BlogLink = styled.a`
  ${CustomLink};
`

const Nav = styled.nav`
  align-items: center;
  display: none;

  ${({ theme }) => theme.media.above("large")} {
    display: flex;
  }
`
const MobileNav = styled.nav<{ active: boolean; bgColor: any }>`
  position: absolute;
  width: 100%;
  height: calc(100vh - 85px);
  top: 85px;
  z-index: 9;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : `#fafafa`)};
  transition: ${({ theme }) => theme.transition.ease()};
  transform: ${({ active }) =>
    active ? `translateY(0%)` : `translateY(-110vh)`};
  opacity: ${({ active }) => (active ? `1` : `0`)};
  user-select: ${({ active }) => (active ? `none` : `unset`)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
`

const MobileText = styled.p`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`

const ButtonGoToApp = styled(Button)`
  margin-bottom: 24px;
`
const Copyright = styled.div`
  padding: 15px 20px 15px 0;
  color: ${({ theme }) => theme.colors.grayscale.mediumLight};
`
const MobileNavLink = styled(LinkWrap)`
  position: relative;
  width: 100%;
  padding: ${({ goesOut }) => (goesOut ? `15px 0` : `15px 20px 15px 0`)};
  color: ${({ theme }) => theme.colors.grayscale.dark};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayscale.paleWhite};
  display: inline-flex;

  &:last-child {
    border-bottom: 0px;
  }

  &:after {
    display: ${props => (props.goesOut ? "block" : "none")};
    content: "";
    position: relative;
    background-image: url(${LeaveArrow});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 6px;
    width: 8px;
    transition: ${({ theme: { transition } }) => transition.ease(200)};
    text-align: left;
  }

  &:hover {
    &:after {
      transform: translate(2px, -2px);
    }
  }
`

const MobileBtn = styled.button<{ active: boolean }>`
  position: relative;
  right: 0;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 4px;
    right: 0;
    height: 2px;
    width: 16px;
    background-color: ${({ theme }) => theme.colors.grayscale.dark};
    transition: ${({ theme }) => theme.transition.ease(200)};
    transform: ${({ active }) =>
      active
        ? `rotate(-45deg) translate(-1px,2px)`
        : `rotate(0) translate(0px, 0px)`};
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 4px;
    right: 0;
    height: 2px;
    width: 16px;
    background-color: ${({ theme }) => theme.colors.grayscale.dark};
    transition: ${({ theme }) => theme.transition.ease(200)};
    transform: ${({ active }) =>
      active
        ? `rotate(45deg) translate(-2px,-3px)`
        : `rotate(0) translate(0px, 0px)`};
  }

  display: block;

  ${({ theme }) => theme.media.above("large")} {
    display: none;
  }
`

const NavActionable = styled(Button)`
  margin-left: 2em;
`

const MobileNavActionable = styled(Button)`
  padding: 6px 8px;
  ${({ theme }) => theme.media.above("large")} {
    display: none;
  }
`

const FixedHeader = styled(Headroom)<{ active: boolean; bgColor: any }>`
  .headroom--scrolled ${HeaderWrapper} {
    background-color: ${({ bgColor }) => (bgColor ? bgColor : `#fafafaf5`)};
    box-shadow: 0px 0.5px 0
      ${({ theme }) => theme.colors.grayscale.shadowHeader};
  }
`

const SocialCol = styled.div`
  flex: 0 1 65px;
  display: flex;
  padding: 15px 20px;
`

const SocialLink = styled.a`
  max-width: 24px;
  width: 100%;
  margin: 0 6px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

interface IProp {
  bgColor?: any
}

const Header: React.FC<IProp> = ({ bgColor }) => {
  const [active, setActive] = React.useState(false)
  const data = useStaticQuery<HeaderProps>(query)

  const content = data.contentYaml.homepage.header
  const menu = content.menu
  const mobileMenu = content.mobile_menu
  const socialContent = data.contentYaml.footer.social

  return (
    <FixedHeader active={active} bgColor={bgColor}>
      {active && <DisableScroll active={active} />}
      <HeaderWrapper bgColor={bgColor}>
        <HeaderContainer bgColor={bgColor}>
          <Wrapper>
            <LogoWrapper to="/" aria-label={data.site.siteMetadata.title}>
              <Logo />
            </LogoWrapper>
            <Nav>
              <BlogLink href={menu[0].link}>{menu[0].text}</BlogLink>
              <DesktopLink
                href={menu[1].link}
                target="_blank"
                rel="noopener norefer"
              >
                {menu[1].text}
              </DesktopLink>
              <NavActionable href={menu[2].link} aria-label={menu[2].text}>
                {menu[2].text}
              </NavActionable>
            </Nav>

            <motion.div
              animate={{
                opacity: active ? 0 : 1,
              }}
            >
              <MobileNavActionable
                href={menu[2].link}
                aria-label={menu[2].text}
              >
                {menu[2].text}
              </MobileNavActionable>
            </motion.div>

            <MobileBtn active={active} onClick={() => setActive(!active)} />
          </Wrapper>
        </HeaderContainer>
        <MobileNav active={active} bgColor={bgColor}>
          <Container>
            <MobileText>{content.mobile_text}</MobileText>
            <ButtonGoToApp href={content.button_go_to_app.link}>
              {content.button_go_to_app.label}
            </ButtonGoToApp>
            {mobileMenu.map(item => {
              return (
                <MobileNavLink
                  key={item.text}
                  goesOut={item.goes_out}
                  to={item.link}
                >
                  {item.text}
                </MobileNavLink>
              )
            })}
            <Copyright aria-disabled>{content.copyright}</Copyright>
          </Container>
          <SocialCol>
            {socialContent.map(social => {
              return (
                <SocialLink
                  href={social.src}
                  target="_blank"
                  rel="noopener norefer"
                  key={social.alt}
                >
                  <Img
                    alt={social.alt}
                    fluid={social.icon.childImageSharp.fluid}
                  />
                </SocialLink>
              )
            })}
          </SocialCol>
        </MobileNav>
      </HeaderWrapper>

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="blur">
            <feGaussianBlur stdDeviation="5" />
          </filter>
        </defs>
      </svg>
    </FixedHeader>
  )
}

interface HeaderProps {
  site: {
    siteMetadata: {
      title: string
    }
  }
  contentYaml: {
    homepage: {
      header: {
        mobile_text: string
        copyright: string
        button_go_to_app: {
          link: string
          label: string
        }
        icon: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
        menu: Array<{
          link: string
          text: string
          goes_out: boolean
        }>
        mobile_menu: Array<{
          link: string
          goes_out: boolean
          text: string
        }>
      }
    }
    footer: {
      social: Array<{
        label: string
        src: string
        icon: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
        alt: string
      }>
    }
  }
}

export const query = graphql`
  query Header {
    contentYaml(homepage: { button: { ne: null } }) {
      homepage {
        header {
          mobile_text
          copyright
          button_go_to_app {
            link
            label
          }
          icon {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          menu {
            link
            goes_out
            text
          }
          mobile_menu {
            link
            goes_out
            text
          }
        }
      }
      footer {
        social {
          alt
          label
          src
          icon {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Header
