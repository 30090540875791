export const globalWindow: any = typeof (global as any).window !== "undefined"

const currentUrl = globalWindow.location ? globalWindow.location.href : ""

export const endpoint =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:9000"
    : '/api'

export const NEWSLETTER_URL = `${endpoint}/newsletter`
//export const URL_HOMEPAGE_STATS = `${endpoint}/homepage-stats`

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
